import { darken } from "polished";
import styled from "styled-components";
import { colors } from "../../styles";
import { Button as ButtonComponent } from 'antd';

const StyledButton = styled(ButtonComponent)`
  cursor: pointer;
  color: ${colors.primary};
  border: none;
  outline: none;
  padding: 5px 15px;
  transition: all 0.3s linear;
  padding: 0px;

  &:hover, &:active, &:focus {
    color: ${darken(0.15, colors.primary)};
  }
`;

type Props = React.ComponentProps<typeof ButtonComponent> & {};

const InlineButton: React.FC<Props> = ({ children, ...passThroughProps }) => (
  <StyledButton {...passThroughProps} type="link">
    {children}
  </StyledButton>
);

export default InlineButton;
