import { Method } from "./d";

const api = <T, Q = never>(
  url: string,
  method: Method,
  payload?: Q
): Promise<T> =>
  fetch(`${process.env.REACT_APP_API_URL}${url}`, {
    method,
    body: JSON.stringify(payload),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(async (response) =>
    response.status >= 400
      ? Promise.reject(await response.json())
      : Promise.resolve(await response.json())
  );

export const get = <T>(url: string): Promise<T> => api(url, "GET");

export const post = <T, Q>(url: string, payload?: Q): Promise<T> =>
  api(url, "POST", payload);

export const patch = <T, Q>(url: string, payload?: Q): Promise<T> =>
  api(url, "PATCH", payload);

export const remove = <T, Q>(url: string, payload?: Q): Promise<T> =>
  api(url, "DELETE", payload);

export const toParams = <T extends object>(params?: T): string =>
  params
    ? "?" +
      Object.keys(params)
        .filter(key => (params as any)[key])
        .map((key) => `${key}=${(params as any)[key]}`)
        .join("&")
    : "";
