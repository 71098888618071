import { Routes, Route } from "react-router-dom";
import WorkhoursView from "./views/WorkhoursView";

const WorkhoursRoutes: React.FC = () => (
  <Routes>
    <Route path="/workhours" element={<WorkhoursView />} />
  </Routes>
);

export default WorkhoursRoutes;
