import { Col } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../styles";

type Props = {
  location: string;
  altLocation?: string;
  icon: React.ReactElement;
  title: string;
};

const Container: any = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${colors.background};
  position: relative;

  .anticon {
    position: relative;
    color: ${colors.background};
    font-size: 20px;
    z-index: 9;
  }

  .icon {
    &::before {
      content: "";
      background-color: ${({ active }) => active && colors.selected};
      border-radius: 100%;
      width: 22px;
      height: 22px;
      position: absolute;
      margin-left: -1px;
      margin-top: -1px;
    }
  }
`;

const BottomTabButton: React.FC<Props> = ({ icon, location, title, altLocation }) => {
  const href = useLocation();

  return (
    <Link to={location}>
      <Col>
      <Container active={location === href.pathname || altLocation === href.pathname}>
        <div className="icon">
          {icon}
        </div>

        {title}
      </Container>
      </Col>
    </Link>
  );
};

export default BottomTabButton;
