import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Form, Input, Menu, Row } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LoadableFetcher } from "../../../components/async";
import { Button, InlineButton } from "../../../components/button";
import { Spacing } from "../../../components/container";
import { Filter } from "../../../components/filter";
import { Columns, Table } from "../../../components/table";
import { Title } from "../../../components/typography";
import { usePromise } from "../../../hooks";
import useWindow from "../../../hooks/useWindow";
import { failure } from "../../../lib/messaging";
import DeleteLocation from "../components/DeleteLocation";
import { Location, LocationsFilter } from "../d";
import { getAll } from "../services";

const LocationsView = () => {
  const mode = useWindow();

  const [filters, setFilters] = useState<LocationsFilter>({});

  const [get, { data: locations, loading }] = usePromise(
    () => getAll(filters),
    undefined,
    () => failure()
  );

  useEffect(() => {
    get();
  }, [filters]);

  const columns: Columns = [
    {
      title: "Lokalizacja",
      dataIndex: "name",
    },
    {
      title: "Koszt roboczogodziny",
      render: ({ hourPrice, currency }: Location) => `${hourPrice} ${currency}`,
    },
    {
      width: 250,
      title: "Akcja",
      render: ({ id }: Location) => (
        <Dropdown
          overlay={
            <Menu>
              <Link to={`/locations/${id}`}>
                <Menu.Item>Edytuj</Menu.Item>
              </Link>
  
              <Menu.Item>
                <DeleteLocation id={id} onRemoved={get} />
              </Menu.Item>
            </Menu>
          }
        >
          <InlineButton style={{ width: "100%", textAlign: "left" }}>
            Akcja <DownOutlined />
          </InlineButton>
        </Dropdown>
      ),
    },
  ];
  
  const mobileColumns: Columns = [
    {
      title: "",
      render: ({ id, name }: Location) => (
        <>
          <Spacing>
            {name}
          </Spacing>
    
          <Link to={`/locations/${id}`}>
            <InlineButton style={{ marginRight: 15 }}>Edytuj</InlineButton>
          </Link>
  
          <DeleteLocation primary={true} id={id} onRemoved={get} />
        </>
      ),
    },
  ];

  return (
    <>
      <Spacing>
        <Filter onFinish={setFilters} onReset={() => setFilters({})}>
          <Form.Item name="location" label="Lokalizacja">
            <Input size={mode === 'mobile' ? 'large' : 'middle'} />
          </Form.Item>
        </Filter>
      </Spacing>

      <Row justify="space-between" style={{ marginBottom: 25 }}>
        <Title>Lokalizacje</Title>

        <Link to="/locations/new">
          <Button>Dodaj nową lokalizację</Button>
        </Link>
      </Row>

      <LoadableFetcher loading={loading}>
        <Table
          dataSource={locations}
          columns={mode === "mobile" ? mobileColumns : columns}
        />
      </LoadableFetcher>
    </>
  );
};

export default LocationsView;
