import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { colors } from "../../styles";

const Container: any = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.onPrimary};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
`;

type Props = PropsWithChildren & {
  loading: boolean;
};

const SpashScreen: React.FC<Props> = ({ children, loading }) => {
  if (!loading) {
    return <>{children}</>;
  }

  return (
    <Container>
      <img
        src={require("../../assets/ic_logo.png")}
        style={{ width: 75 }}
        alt="logo"
      />
    </Container>
  );
};

export default SpashScreen;
