import { Affix, Calendar, Col, Form, Modal, Row, Select } from "antd";
import { useEffect, useState } from "react";

import { Spacing } from "../../../components/container";
import { usePromise } from "../../../hooks";
import { formatCurrentDate, formatDate, now } from "../../../lib/date";
import { DayReports, NewEntry, ReportForm } from "../components";
import { getMyReports } from "../services";
import { startOfMonth, endOfMonth, formatISO } from "../../../lib/date";
import styled from "styled-components";
import { failure } from "../../../lib/messaging";
import { colors } from "../../../styles";
import { darken } from "polished";

import moment from 'moment';

const StyledCalendar = styled(Calendar)`
  @media screen and (max-width: 892px) {
    tr {
      display: flex;
      flex-direction: column;
      max-height: unset;

      > div {
        max-height: unset;
      }
    }

    thead {
      display: none;
    }

    .ant-picker-calendar-mode-switch {
      display: none;
    }

    .ant-picker-cell:not(.ant-picker-cell-in-view) {
      display: none;
    }
  }
`;

type Props = {
  onSubmit: (date: Date) => void;
};

const Picker: React.FC<Props> = ({ onSubmit }) => {
  const [month, setMonth] = useState(
    parseInt(formatCurrentDate("MM"))
  );
  const [year, setYear] = useState(parseInt(formatCurrentDate("yyyy")));

  useEffect(() => {
    onSubmit(new Date(`${year}-${month < 10 ? `0${month}` : month}-01`));
  }, [month, year]);

  return (
    <Row gutter={[15, 15]} justify="end">
      <Col md={4} span={12}>
        <Select
          size="large"
          value={year}
          style={{ width: "100%" }}
          onSelect={setYear}
        >
          <Select.Option value={2022}>2022</Select.Option>
          <Select.Option value={2023}>2023</Select.Option>
          <Select.Option value={2024}>2024</Select.Option>
        </Select>
      </Col>

      <Col md={4} span={12}>
        <Select
          size="large"
          value={month}
          onSelect={setMonth}
          style={{ width: "100%" }}
        >
          <Select.Option value={1}>Styczeń</Select.Option>
          <Select.Option value={2}>Muty</Select.Option>
          <Select.Option value={3}>Marzec</Select.Option>
          <Select.Option value={4}>Kwiecieć</Select.Option>
          <Select.Option value={5}>Maj</Select.Option>
          <Select.Option value={6}>Czerwiec</Select.Option>
          <Select.Option value={7}>Lipiec</Select.Option>
          <Select.Option value={8}>Sierpień</Select.Option>
          <Select.Option value={9}>Wrzesień</Select.Option>
          <Select.Option value={10}>Październik</Select.Option>
          <Select.Option value={11}>Listopad</Select.Option>
          <Select.Option value={12}>Grudzień</Select.Option>
        </Select>
      </Col>
    </Row>
  );
};

const NewReport: React.FC = () => {
  const [selecteDate, setSelectedDate] = useState<Date | undefined>(undefined);

  const [get, { data }] = usePromise(getMyReports, undefined, () => failure());

  const getAll = (date?: Date) => {
    get({
      startDate: formatISO(startOfMonth(date || selecteDate || new Date())),
      endDate: formatISO(endOfMonth(date || selecteDate || new Date())),
    });
  };

  useEffect(() => {
    getAll(now());
  }, []);

  return (
    <>
      <Spacing>
        <StyledCalendar
          headerRender={({ onChange }) => (
            <Spacing>
              <Affix offsetTop={0}>
                <div
                  style={{
                    backgroundColor: darken(0.05, colors.onPrimary),
                    padding: 10,
                  }}
                >
                  <Picker
                    onSubmit={(date) => {
                      onChange(moment(date.toString()));
                      getAll(date)
                    }}
                  />
                </div>
              </Affix>
            </Spacing>
          )}
          dateCellRender={(date) => (
            <DayReports
              date={new Date(date.toISOString())}
              reports={data?.reports || []}
              onSelect={(date) => setSelectedDate(date)}
            />
          )}
        />
      </Spacing>

      <Modal
        visible={!!selecteDate}
        onCancel={() => setSelectedDate(undefined)}
        title={formatDate(selecteDate ? selecteDate : new Date())}
        footer={null}
        width={800}
      >
        <Form layout="vertical">
          {data?.reports &&
            selecteDate &&
            data.reports
              .filter(
                (report) =>
                  formatDate(new Date(report.date)) === formatDate(selecteDate)
              )
              .map((report, i) => (
                <ReportForm
                  title={`#${i + 1}`}
                  key={report.id}
                  report={report}
                  options={data.locations || []}
                  onRemoved={() => getAll()}
                  onEdited={() => getAll()}
                />
              ))}
        </Form>

        {selecteDate && (
          <NewEntry
            date={selecteDate}
            options={data?.locations || []}
            onAdded={() => getAll()}
          />
        )}
      </Modal>
    </>
  );
};

export default NewReport;
