import { Form } from "antd";
import styled from "styled-components";
import { Button } from "../../../components/button";
import { Spacing } from "../../../components/container";
import { Bold, Title } from "../../../components/typography";
import { useAuth } from "../../../contexts/auth";
import { usePromise } from "../../../hooks";
import useWindow from "../../../hooks/useWindow";
import { failure, success } from "../../../lib/messaging";
import { signOut as signOutAsync } from "../../../services/auth";
import { colors } from "../../../styles";
import { PasswordForm } from "../components";
import { changeMyPassword } from "../services";

const SignOutButton = styled(Button)`
  width: 100%;
  color: ${colors.primary};
  border: 2px solid ${colors.primary};
  background-color: ${colors.onPrimary};

  &:active, &:hover, &:focus {
    border: 2px solid ${colors.primary};
    background-color: ${colors.primary};
    color: ${colors.onPrimary};
  }
`;

const AccountView = () => {
  const mode = useWindow();

  const [change, { loading }] = usePromise(
    changeMyPassword,
    () => success("Hasło zostało zmienione"),
    () => failure()
  );

  const { state, dispatch } = useAuth();

  const signOut = () =>
    signOutAsync().then(() => dispatch({ type: "SIGN_OUT" }));

  return (
    <>
      {mode === 'mobile' && (
        <Spacing>
          <Title>{state.user?.firstName} {state.user?.lastName}</Title>
        </Spacing>
      )}

      <Spacing>
        <Title>Zmiana hasła</Title>
      </Spacing>

      <Spacing>
        <Form layout="vertical" onFinish={({ password }) => change(password)}>
          <PasswordForm />

          <Button
            loading={loading}
            htmlType="submit"
            size={mode === "mobile" ? "large" : "middle"}
          >
            Zmień hasło
          </Button>
        </Form>
      </Spacing>

      {mode === "mobile" && (
        <>
          <Spacing>
            <Title>Zarządzanie</Title>
          </Spacing>

          <SignOutButton
            size={mode === "mobile" ? "large" : "middle"}
            onClick={signOut}
          >
            Wyloguj się
          </SignOutButton>
        </>
      )}
    </>
  );
};

export default AccountView;
