import { formatDate } from "../../../lib/date";
import { get, patch, post, remove, toParams } from "../../../lib/remote";
import {
  AllReportsFilterQuery,
  NewReportPayload,
  Report,
  ReportHistory,
  ReportPayload,
  ReportResponse,
  ReportsFilterQuery,
} from "../d";

export const getMyReports = (params?: ReportsFilterQuery) =>
  get<ReportResponse>(`/reports/mine${toParams(params)}`);

export const getAllReports = (params: AllReportsFilterQuery) =>
  get<Report[]>(`/reports${toParams(params)}`);

export const getUserReports = (id: number, params?: ReportsFilterQuery) =>
  get<Report[]>(`/reports/${id}${toParams(params)}`);

export const addReport = (payload: NewReportPayload) =>
  post(`/reports/`, payload);

export const removeReport = (id: number) => remove(`/reports/${id}`);

export const editReport = (id: number, payload: ReportPayload) =>
  patch(`/reports/${id}`, payload);

export const getReportHistory = (id: number) =>
  get<ReportHistory[]>(`/reports/${id}/history`);

export const generateReport = (params?: AllReportsFilterQuery, id?: number) =>
  fetch(
    `${process.env.REACT_APP_API_URL}/reports/generate${toParams({
      ...params,
      id,
    })}`,
    {
      method: "GET",
      body: undefined,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", `${formatDate(new Date())}.xlsx`);

      document.body.appendChild(link);

      link.click();

      link.parentNode!.removeChild(link);
    });
