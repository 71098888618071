import { darken } from "polished";
import styled from "styled-components";
import { colors } from "../../styles";
import { Button as ButtonComponent } from "antd";

type Variant = 'primary' | 'error';

const StyledButton = styled(ButtonComponent)<{ variant: Variant }>`
  cursor: pointer;
  background-color: ${({ variant }) =>
    variant === "primary" ? colors.primary : colors.error};
  color: ${colors.onPrimary};
  border: none;
  outline: none;
  padding: 5px 15px;
  transition: all 0.3s linear;
  border-radius: 25px;

  &:hover,
  &:active,
  &:focus {
    color: ${colors.onPrimary};
    background-color: ${({ variant }) =>
      darken(0.15, variant === "primary" ? colors.primary : colors.error)};
  }
`;

type Props = React.ComponentProps<typeof ButtonComponent> & {
  variant?: Variant;
};

const Button: React.FC<Props> = ({ children, variant='primary', ...passThroughProps }) => (
  <StyledButton {...passThroughProps} variant={variant}>{children}</StyledButton>
);

export default Button;
