import { Routes, Route } from "react-router-dom";
import SignInView from "./views/SignInView";

const UserRoutes: React.FC = () => (
  <Routes>
    <Route path="*" element={<SignInView />} />
  </Routes>
);

export default UserRoutes;
