import { get, patch, post, remove, toParams } from "../../../lib/remote";

import { Location, LocationPayload, LocationsFilter } from "../d";

export const getCurrencies = () =>
  get<string[]>(`/locations/currencies`);

export const getAll = (params?: LocationsFilter) =>
  get<Location[]>(`/locations${toParams(params)}`);

export const getSingle = (id: number) => get<Location>(`/locations/${id}`);

export const createLocation = (payload: LocationPayload) =>
  post<never, LocationPayload>("/locations", payload);

export const updateLocation = (id: number, payload: LocationPayload) =>
  patch<never, LocationPayload>(`/locations/${id}`, payload);

export const removeLocation = (id: number) =>
  remove(`/locations/${id}`);
