import React, { PropsWithChildren, useContext, useReducer } from 'react';

import { Action, State } from './d';
import reducer from './reducer';

type Auth = {
  state: State;
  dispatch: React.Dispatch<Action>;
};

const AuthContext = React.createContext<Auth | undefined>(undefined);

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    user: undefined,
  });

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): Auth => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};

export { AuthProvider, useAuth };
