import { Menu, MenuTheme } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../contexts/auth'

type Props = {
  theme?: MenuTheme;
  onChanged?: () => void;
}

const SignedInMenu: React.FC<Props> = ({ theme, onChanged }) => {
  const { state: { user }} = useAuth();

  return (
    <Menu theme={theme}>
      <Menu.Item onClick={onChanged}>
        <Link to="/account">
          Moje konto
        </Link>
      </Menu.Item>

      <Menu.Item onClick={onChanged}>
        <Link to="/reports/mine">
          Moje godziny pracy
        </Link>
      </Menu.Item>
  
      {(user?.role === 'admin' || user?.role === 'superadmin') && (
        <>
          <Menu.Item onClick={onChanged}>
            <Link to="/users">
              Pracownicy
            </Link>
          </Menu.Item>

          <Menu.Item onClick={onChanged}>
            <Link to="/locations">
              Lokalizacje
            </Link>
          </Menu.Item>
      
          <Menu.Item onClick={onChanged}>
            <Link to="/reports">
              Godziny pracy
            </Link>
          </Menu.Item>
      
          <Menu.Item onClick={onChanged}>
            <Link to="/workhours">
              Godziny pracy pracownika
            </Link>
          </Menu.Item>
        </>
      )}
    </Menu>
  );
}

export default SignedInMenu