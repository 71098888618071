import { Row } from 'antd';
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { colors } from '../../styles';

const Container: any = styled.div`
  box-shadow: 0px 0px 5px #888;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${colors.onPrimary};
  padding: 15px 25px 10px 25px;
  z-index: 999;
`;

const BottomTab: React.FC<PropsWithChildren> = ({ children }) => (
  <Container>
    <Row justify="space-between">
      {children}
    </Row>
  </Container>
);

export default BottomTab