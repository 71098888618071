import { DownOutlined } from "@ant-design/icons";
import { Col, Dropdown, Form, Input, Menu, Row } from "antd";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { LoadableFetcher } from "../../../components/async";
import { InlineButton } from "../../../components/button";
import { Spacing } from "../../../components/container";
import { Filter } from "../../../components/filter";
import { Columns, Table } from "../../../components/table";
import { Title } from "../../../components/typography";
import { usePromise } from "../../../hooks";
import { formatDate, formatISO } from "../../../lib/date";
import { EditableReport } from "../components";
import { Report, ReportsFilter } from "../d";
import { toWorkTime } from "../mappers";
import { getUserReports } from "../services";
import DatePicker from "../../../components/date/DatePicker";
import { REQUIRED_FIELD } from "../../../messages";
import { useAuth } from "../../../contexts/auth";
import useWindow from "../../../hooks/useWindow";
import moment from "moment";
import { failure } from "../../../lib/messaging";
import { LocationFilter } from "../../locations/components";

const defaultFilters = [moment().add({ day: -31 }), moment()];

const UserReportsView = () => {
  const mode = useWindow();

  const {
    state: { user },
  } = useAuth();

  const [filters, setFilters] = useState<ReportsFilter>({
    date: defaultFilters,
  });

  const [get, { loading, data: reports }] = usePromise(getUserReports, undefined, () => failure());

  const { id, name } = useParams();

  const getAll = () => {
    const { date, location } = filters;

    get(id, {
      startDate: formatISO(new Date(date?.[0].toString() || "")),
      endDate: formatISO(new Date(date?.[1].toString() || "")),
      location,
    });
  };

  useEffect(() => {
    getAll();
  }, [filters]);

  const columns: Columns = [
    {
      title: "Data",
      render: ({ date }: Report) => formatDate(new Date(date)),
    },
    {
      title: "Lokalizacja",
      render: ({ location }: Report) => location.name,
    },
    {
      title: "Czas trwania",
      render: ({ duration }: Report) => toWorkTime(duration),
    },
    {
      title: "Akcja",
      render: (report: Report) => (
        <Dropdown
          overlay={
            <Menu>
              <EditableReport report={report} onEdited={getAll} options={[]}>
                <Menu.Item>Edytuj</Menu.Item>
              </EditableReport>

              {user?.role === "superadmin" && (
                <Link to={`/reports/${report.id}/history`}>
                  <Menu.Item>Historia zmian</Menu.Item>
                </Link>
              )}
            </Menu>
          }
        >
          <InlineButton style={{ width: "100%", textAlign: "left" }}>
            Akcja <DownOutlined />
          </InlineButton>
        </Dropdown>
      ),
    },
  ];

  const mobileColumns: Columns = [
    {
      title: "Raport",
      render: (report: Report) => (
        <>
          <Spacing>
            <b>Data: </b>
            {formatDate(new Date(report.date))}
          </Spacing>

          <Spacing>
            <b>Czas pracy: </b>
            {toWorkTime(report.duration)}
          </Spacing>

          <Spacing>
            <b>Lokalizacja: </b>
            {report.location.name}
          </Spacing>

          <Spacing>
            <EditableReport report={report} onEdited={getAll} options={[]}>
              <InlineButton>Edytuj</InlineButton>
            </EditableReport>
          </Spacing>

          {user?.role === "superadmin" && (
            <Link to={`/reports/${report.id}/history`}>
              <InlineButton>Historia zmian</InlineButton>
            </Link>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Spacing>
        <Filter<ReportsFilter>
          onFinish={setFilters}
          onReset={() =>
            setFilters({
              date: defaultFilters,
            })
          }
          initial={{
            date: defaultFilters,
          }}
        >
          <Col>
            <Form.Item
              name="date"
              label="Data raportu"
              rules={[{ required: true, message: REQUIRED_FIELD }]}
            >
              <DatePicker.RangePicker size={mode === 'mobile' ? 'large' : 'middle'} />
            </Form.Item>
          </Col>

          <Col>
            <LocationFilter />
          </Col>
        </Filter>
      </Spacing>

      <Spacing>
        <Row align="middle" justify="space-between" gutter={[15, 15]}>
          <Col>
            <Title>{name}</Title>
          </Col>
        </Row>
      </Spacing>

      <LoadableFetcher loading={loading}>
        <Table
          columns={mode === "mobile" ? mobileColumns : columns}
          dataSource={reports}
          rowKey="id"
        />
      </LoadableFetcher>
    </>
  );
};

export default UserReportsView;
