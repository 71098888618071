import { Col, Form, Row } from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LoadableFetcher } from "../../../components/async";
import { Button, InlineButton } from "../../../components/button";
import { Spacing } from "../../../components/container";
import { Title } from "../../../components/typography";
import { usePromise } from "../../../hooks";
import { failure, success } from "../../../lib/messaging";
import { LocationForm } from "../components";
import { getCurrencies, getSingle, updateLocation } from "../services";

const EditLocationView: React.FC = () => {
  const [get, { data: currencies }] = usePromise(getCurrencies, undefined, () =>
    failure()
  );

  const [getLocation, { data, loading }] = usePromise(getSingle, undefined, () =>
    failure()
  );

  const [update, { loading: updating }] = usePromise(
    updateLocation,
    () => success("Lokalizacja została zaktualizowana"),
    () => failure()
  );

  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    get();
    getLocation(id);
  }, []);

  return (
    <LoadableFetcher loading={loading}>
      <Form
        onFinish={values => update(id, values)}
        layout="vertical"
        initialValues={data}
      >
        <Spacing>
          <Row align="middle" gutter={[25, 25]}>
            <Col>
              <InlineButton onClick={() => navigate(-1)}>Wstecz</InlineButton>
            </Col>
            <Col>
              <Title>Edytowanie lokalizacji</Title>
            </Col>
          </Row>
        </Spacing>

        <LocationForm currencies={currencies || []} />

        <Button loading={updating} htmlType="submit">
          Zapisz
        </Button>
      </Form>
    </LoadableFetcher>
  );
};

export default EditLocationView;
