import { UserOutlined } from "@ant-design/icons";
import { Button, Row, Typography } from "antd";
import { Avatar as AvatarComponent } from "antd";
import { useAuth } from "../../contexts/auth";
import { signOut as signOutAsync } from "../../services/auth";

const Avatar: React.FC = () => {
  const {
    state: { user },
    dispatch,
  } = useAuth();

  const signOut = () => signOutAsync()
    .then(() => dispatch({ type: 'SIGN_OUT' }));

  const { firstName, lastName } = user || {};

  return (
    <Row align="middle">
      <Typography style={{ marginRight: 24 }}>
        {firstName} {lastName}
      </Typography>

      <AvatarComponent shape="circle" icon={<UserOutlined />} />

      <Button type="link" onClick={signOut}>
        Wyloguj
      </Button>
    </Row>
  );
};

export default Avatar;
