import { Skeleton } from "antd";
import { PropsWithChildren } from "react";

type Props = PropsWithChildren & {
  loading: boolean;
}

const LoadableFetcher: React.FC<Props> = ({ loading, children }) => (
  loading ? <Skeleton /> : <>{children}</>
);

export default LoadableFetcher;
