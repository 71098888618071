import { Modal } from "antd";
import React from "react";
import { InlineButton } from "../../../components/button";
import { usePromise } from "../../../hooks";
import { failure, success } from "../../../lib/messaging";
import { paraller } from "../../../lib/utils";
import { colors } from "../../../styles";
import { removeLocation } from "../services";

type Props = {
  id: number;
  primary?: boolean;
  onRemoved: () => void;
};

const DeleteLocation: React.FC<Props> = ({ id, primary, onRemoved }) => {
  const [remove, { loading }] = usePromise(
    removeLocation,
    () => paraller(() => success("Lokalizacja została usunięta"), onRemoved),
    () => failure()
  );

  const confirm = () =>
    Modal.confirm({
      okButtonProps: {
        style: {
          backgroundColor: colors.primary,
          borderColor: colors.primary,
        },
      },
      onOk: () => remove(id),
      title: "Czy na pewno chcesz usunąć tą lokalizację?",
    });

  return (
    <InlineButton
      onClick={confirm}
      loading={loading}
      style={primary ? {
        color: colors.primary
      } : {
        color: primary ? colors.primary : "rgba(0, 0, 0, 0.85)",
        display: "block",
        width: "100%",
        textAlign: "left",
      }}
    >
      Usuń
    </InlineButton>
  );
};

export default DeleteLocation;
