import { Routes, Route } from "react-router-dom";
import EditLocationView from "./views/EditLocationView";
import LocationsView from "./views/LocationsView";
import NewLocationView from "./views/NewLocationView";

const LocationsRoutes: React.FC = () => (
  <Routes>
    <Route path="/locations" element={<LocationsView />} />
    <Route path="/locations/new" element={<NewLocationView />} />
    <Route path="/locations/:id" element={<EditLocationView />} />
  </Routes>
);

export default LocationsRoutes;
