import { Tag } from "antd";
import React from "react";
import { State } from "../d";

const states: Record<State, React.ReactElement> = {
  CREATED: <Tag color="green">Utworzono</Tag>,
  UPDATED: <Tag color="orange">Zmodyfikowano</Tag>,
  DELETED: <Tag color="red">Usunięto</Tag>,
};

export const toState = (type: State) => states[type];
