import { Modal } from "antd";
import React, { useState, PropsWithChildren } from "react";
import { usePromise } from "../../../hooks";
import { failure } from "../../../lib/messaging";
import { Location, Report } from "../d";
import { editReport } from "../services";
import Entry from "./Entry";

type Props = PropsWithChildren & {
  report: Report;
  options: Location[];
  onEdited: () => void;
};

const EditableReport: React.FC<Props> = ({
  children,
  report,
  options,
  onEdited,
}) => {
  const [visible, setVisible] = useState(false);

  const [edit, { loading }] = usePromise(editReport, onEdited, () => failure());

  const { date, startAt, endAt, id, location, hasBreak } = report;

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        title="Edytuj raport godzin pracy"
      >
        <Entry
          loading={loading}
          options={options}
          initial={{ date, startAt, endAt, location: location.id, hasBreak }}
          onSubmit={(payload) => edit(id, payload)}
          onCancel={() => setVisible(false)}
        />
      </Modal>

      <div onClick={() => setVisible(true)}>{children}</div>
    </>
  );
};

export default EditableReport;
