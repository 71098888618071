import { createGlobalStyle } from 'styled-components';
import { colors } from '../../styles';
 
const GlobalStyle = createGlobalStyle`
  a {
    color: ${colors.primary};
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #F2B544;
    
    a {
      color: #000;
    }
  }
  
  .css-z3au5x-MuiButtonBase-root-MuiIconButton-root-MuiPickersToolbar-penIconButton {
    display: none !important;
  }

  @media screen and (max-width: 892px) {
    padding: 20px;

    .ant-picker-panels { 
      flex-direction: column !important;
    }
  }
`;
 
export default GlobalStyle as any;
