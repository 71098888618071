import { notification } from "antd"

export const success = (message: string) => notification.open({
  message,
  type: 'success',
});

export const failure = (traceId?: string, message?: string) => notification.open({
  message: 'Akcja nie została wykonana',
  description: message || `Nie udało się wykonać akcji. Proszę spróbować ponownie później albo zalogować się jeszcze raz do aplikacji. Jeśli problem będzie się powtarzał, proszę skontaktować się z administratorem. ${traceId ? `Kod błędy: ${traceId}` : ''}`,
  type: 'error',
  duration: 10,
});
