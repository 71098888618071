import React, { useState } from "react";
import { Button } from "../../../components/button";
import { usePromise } from "../../../hooks";
import { failure } from "../../../lib/messaging";
import { updateStatus } from "../services";

type Props = {
  id: number;
  active: boolean;
};

const SetStatus: React.FC<Props> = ({ id, active }) => {
  const [isActive, setActive] = useState(active);

  const [update, { loading }] = usePromise(updateStatus, () =>
    setActive(!isActive),
    () => failure(),
  );

  return (
    <Button
      variant={isActive ? "error" : "primary"}
      loading={loading}
      onClick={() => update(id, !isActive)}
    >
      {isActive
        ? "Tymczasowo dezaktywuj konto użytkownika"
        : "Aktywuj konto użytkownika"}
    </Button>
  );
};

export default SetStatus;
