import { Modal } from "antd";
import { Button } from "../../../components/button";
import { usePromise } from "../../../hooks";
import { addReport } from "../services";
import Entry from "./Entry";
import { useState } from 'react';
import { failure } from "../../../lib/messaging";
import { Location } from "../d";

type Props = {
  date: Date;
  options: Location[];
  onAdded: () => void,
};

const NewEntry: React.FC<Props> = ({ date, options, onAdded }) => {
  const [visible, setVisible] = useState(false);

  const [add, { loading }] = usePromise(
    addReport,
    onAdded,
    () => failure(),
  );

  return (
    <>
      <Modal
        visible={visible}
        title="Dodaj godziny pracy"
        footer={null}
        destroyOnClose={true}
        onCancel={() => setVisible(false)}
      >
        <Entry
          selectedDate={date}
          loading={loading}
          options={options}
          onCancel={() => setVisible(false)}
          onSubmit={({ location, date, startAt, endAt, hasBreak }) => {
            add({ date, location, startAt, endAt, hasBreak })
            setVisible(false);
          }}
        />
      </Modal>

      <Button onClick={() => setVisible(true)}>Dodaj godziny</Button>
    </>
  );
};

export default NewEntry;
