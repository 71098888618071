import { Col, Form, Row } from "antd";
import { useState } from "react";
import { Button, InlineButton } from "../../../components/button";
import { Spacing } from "../../../components/container";
import { Location, ReportPayload } from "../d";
import TimeSelector from "./TimeSelector";

import moment from "moment";
import {
  Alert,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";

import { MobileDatePicker } from "@mui/x-date-pickers";
import { formatISO } from "date-fns";
import { Text } from "../../../components/typography";
import { ClockCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { toWorkTime } from "../mappers";

type Props = {
  selectedDate?: Date;
  loading: boolean;
  options: Location[];
  initial?: ReportPayload;
  onSubmit: (values: ReportPayload) => void;
  onCancel: () => void;
};

const endDateDuration = 1440; // 24:00

const endsAtEndDate = (endAt: number) => endAt === 0;

const Entry: React.FC<Props> = ({
  initial,
  options,
  loading,
  selectedDate,
  onSubmit,
  onCancel,
}) => {
  const [startAt, setStartAt] = useState(initial?.startAt ?? 360);

  const [endAt, setEndAt] = useState(initial?.endAt ?? 1020);

  const [hasBreak, setBreak] = useState(initial?.hasBreak ?? true);

  const [location, setLocation] = useState(initial?.location ?? 0);

  const [date, setDate] = useState<string | Date>(
    initial?.date ?? selectedDate ?? new Date()
  );

  const isInvalidTime = () =>
    endAt < 0 ||
    (!endsAtEndDate(endAt) && startAt >= endAt) ||
    (!endsAtEndDate(endAt) && endAt - startAt <= 30 && hasBreak);

  const isInvalid = () => startAt < 0 || isInvalidTime() || location === 0;

  return (
    <>
      <Form
        layout="vertical"
        initialValues={{
          ...initial,
          date: initial ? moment(initial.date) : moment(selectedDate),
        }}
        onFinish={() =>
          onSubmit({
            location,
            date: formatISO(
              new Date(moment(date).startOf("day").add(12, "hours").toString())
            ),
            startAt,
            endAt: endsAtEndDate(endAt) ? endDateDuration : endAt,
            hasBreak,
          })
        }
      >
        <Spacing>
          <MobileDatePicker
            label="Data raportu"
            value={date}
            onChange={(e) => e && setDate(e)}
            renderInput={(params) => (
              <TextField {...params} style={{ width: "100%" }} />
            )}
          />
        </Spacing>

        <Spacing>
          <TimeSelector
            label="Data rozpoczęcia"
            value={startAt}
            onSelected={setStartAt}
          />
        </Spacing>

        <Spacing>
          <TimeSelector
            label="Data zakończenia"
            value={endAt}
            onSelected={setEndAt}
          />

          <Text style={{ marginTop: 10 }}>
            <InfoCircleOutlined /> Data zakończenia, która ustawiona jest na
            00:00 oznacza północ w danym dniu (24:00)
          </Text>

          {!isInvalidTime() && (
            <Text style={{ marginTop: 5 }}>
              <ClockCircleOutlined /> Wybrany czas pracy:{" "}
              {toWorkTime(
                (endsAtEndDate(endAt)
                  ? endDateDuration - startAt
                  : endAt - startAt) - (hasBreak ? 30 : 0)
              )}
            </Text>
          )}
        </Spacing>

        <Spacing>
          <FormControlLabel
            label="Przerwa w trakcie pracy?"
            control={
              <Checkbox
                checked={hasBreak}
                onChange={(e) => setBreak(e.target.checked)}
              />
            }
          />
        </Spacing>

        <Spacing>
          <FormControl fullWidth={true}>
            <InputLabel id="demo-simple-select-label">Lokalizacja</InputLabel>
            <Select
              placeholder="Wprowadź miejsce pracy"
              label="Miejsce pracy"
              style={{ width: "100%" }}
              value={location}
              onChange={(location) =>
                setLocation(parseInt(location.target.value.toString()))
              }
            >
              {options.map(({ id, name }) => (
                <MenuItem value={id}>{name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Spacing>

        <Spacing>
          <Row gutter={15}>
            <Col>
              <Button
                htmlType="submit"
                loading={loading}
                disabled={isInvalid()}
              >
                Zapisz
              </Button>
            </Col>
            <Col>
              <InlineButton onClick={onCancel}>Anuluj</InlineButton>
            </Col>
          </Row>
        </Spacing>

        {((!endsAtEndDate(endAt) && startAt >= endAt) ||
          location === 0 ||
          (!endsAtEndDate(endAt) &&
            Math.abs(endAt - startAt) <= 30 &&
            hasBreak)) && (
          <Spacing>
            <Alert severity="error">
              <ul style={{ paddingLeft: 20, marginBottom: 0 }}>
                {!endsAtEndDate(endAt) && startAt >= endAt && (
                  <li>
                    Wybrana godzina rozpoczęcia pracy musi być przed godziną
                    zakończenia
                  </li>
                )}

                {location === 0 && <li>Miejsce pracy nie zostało ustawione</li>}

                {!endsAtEndDate(endAt) &&
                  Math.abs(endAt - startAt) <= 30 &&
                  hasBreak && (
                    <li>
                      Różnica pomiędzy godziną rozpoczęcia i godziną zakończenia
                      musi być większa niż 30 minut
                    </li>
                  )}
              </ul>
            </Alert>
          </Spacing>
        )}
      </Form>
    </>
  );
};

export default Entry;
