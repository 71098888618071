import { formatDate } from "../../../lib/date";
import { get, toParams } from "../../../lib/remote";
import { AllWorkhoursFilter, Workhour } from "../d";

export const getAllWorkhours = (params: AllWorkhoursFilter) =>
  get<Workhour[]>(`/workhours${toParams(params)}`);

export const generateReport = (params?: AllWorkhoursFilter) =>
  fetch(
    `${process.env.REACT_APP_API_URL}/workhours/report${toParams(params)}`,
    {
      method: "GET",
      body: undefined,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", `${formatDate(new Date())}.xlsx`);

      document.body.appendChild(link);

      link.click();

      link.parentNode!.removeChild(link);
    });
