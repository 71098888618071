import { Form, Input } from "antd";
import useWindow from "../../../hooks/useWindow";

const PasswordForm = () => {
  const mode = useWindow();

  return (<>
    <Form.Item
      label="Hasło"
      name="password"
      rules={[{ required: true, message: "Pole nie może być puste" }]}
    >
      <Input size={mode === 'mobile' ? 'large' : 'middle'} type="password" placeholder="Wprowadź hasło"  />
    </Form.Item>

    <Form.Item
      label="Powtórz hasło"
      name="repeatPassword"
      dependencies={["password"]}
      rules={[
        {
          required: true,
          message: "Pole nie może być puste",
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue("password") === value) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error("Wprowadzone hasła nie są takie same")
            );
          },
        }),
      ]}
    >
      <Input size={mode === 'mobile' ? 'large' : 'middle'} type="password" placeholder="Powtórz hasło"  />
    </Form.Item>
  </>
);
}

export default PasswordForm;
