import { Col, Form, Row } from "antd";
import { Store } from "antd/lib/form/interface";
import { darken } from "polished";
import { PropsWithChildren } from "react";
import styled from "styled-components";
import useWindow from "../../hooks/useWindow";
import { colors } from "../../styles";
import { Button, InlineButton } from "../button";
import { Spacing } from "../container";
import { Title } from "../typography";

const Container: any = styled.div`
  padding: 25px;
  background-color: ${darken(0.02, colors.onPrimary)};
  border-bottom: 2px solid ${colors.primary};
`;

type Props<T> = PropsWithChildren & {
  initial?: T & Store;
  onFinish: (values: T) => void;
  onReset: () => void;
};

const Filter = <T,>({ initial, children, onFinish, onReset }: Props<T>) => {
  const mode = useWindow();

  return (
    <Container>
      <Spacing>
        <Title>Wyszukiwanie</Title>
      </Spacing>

      <Form
        layout="vertical"
        onFinish={onFinish}
        onReset={() => onReset()}
        initialValues={initial}
      >
        <Row align="top" gutter={mode === "mobile" ? [5, 5] : [25, 25]}>
          {children}

          {mode === "desktop" && (
            <>
              <Col>
                <Form.Item label=" ">
                  <Button htmlType="submit">Szukaj</Button>
                </Form.Item>
              </Col>

              <Col>
                <Form.Item label=" ">
                  <InlineButton htmlType="reset">Wyczyść</InlineButton>
                </Form.Item>
              </Col>
            </>
          )}

          {mode === "mobile" && (
            <Col span={24}>
              <Row gutter={[10, 10]}>
                <Col>
                  <Form.Item>
                    <Button size="large" htmlType="submit">
                      Szukaj
                    </Button>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <InlineButton size="large" htmlType="reset">
                      Wyczyść
                    </InlineButton>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Form>
    </Container>
  );
};

export default Filter;
