import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";

import plPl from "antd/es/locale/pl_PL";
import { ConfigProvider } from "antd";

import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./contexts/auth";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";

import "antd/dist/antd.css";
import { GlobalStyle } from "./components/style";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <ConfigProvider locale={plPl}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <GlobalStyle />

          <App />
        </LocalizationProvider>
      </ConfigProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

