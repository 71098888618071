import { Alert } from "@mui/material";
import { Form, Input } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/button";
import { Spacing } from "../../../components/container";
import { useAuth } from "../../../contexts/auth";
import { usePromise } from "../../../hooks";
import { MAXIMUM_LENGTH_EXCEEDED, REQUIRED_FIELD } from "../../../messages";
import { toSignInError } from "../mappers";
import { signIn } from "../services";

const SignInView = () => {
  const { dispatch } = useAuth();

  const navigate = useNavigate();

  const [sign, { loading, data: user, error }] = usePromise(signIn, () => navigate('/'));

  useEffect(() => {
    user && dispatch({ type: "SIGN_IN", user });
  }, [user]);

  return (
    <Form layout="vertical" onFinish={sign}>
      {error && (
        <Spacing>
          <Alert severity="error">{toSignInError(error)}</Alert>
        </Spacing>
      )}

      <Form.Item
        name="login"
        label="Login lub adres email"
        rules={[
          { required: true, message: REQUIRED_FIELD },
          { max: 255, message: MAXIMUM_LENGTH_EXCEEDED(255) },
        ]}
      >
        <Input size="large" placeholder="Wprowadź login albo adres email" />
      </Form.Item>

      <Form.Item
        name="password"
        label="Hasło"
        rules={[
          { required: true, message: REQUIRED_FIELD },
          { max: 255, message: MAXIMUM_LENGTH_EXCEEDED(255) },
        ]}
      >
        <Input size="large" type="password" placeholder="Wprowadź hasło" />
      </Form.Item>

      <Button htmlType="submit" loading={loading} size="large">
        Zaloguj
      </Button>
    </Form>
  );
};

export default SignInView;
