import { useCallback, useReducer } from 'react';

type State<T> = {
  data?: T;
  loading: boolean;
  error?: Error;
};

type Action<T> =
  | { type: 'start-loading' }
  | { type: 'success'; data: T }
  | { type: 'error'; error: Error };

type Reducer<T> = (state: State<T>, action: Action<T>) => State<T>;

type PromiseCreator<T> = (...args: any[]) => Promise<T>;
type PromiseInvoker = (...args: any[]) => void;

const reducer = <T>(state: State<T>, action: Action<T>): State<T> => {
  switch (action.type) {
    case 'start-loading':
      return { ...state, loading: true, data: undefined, error: undefined };

    case 'success':
      return { ...state, loading: false, data: action.data };

    case 'error':
      return { ...state, loading: false, error: action.error };
  }
};

const Ø = () => {};

const usePromise = <T>(
  creator: PromiseCreator<T>,
  onSuccess: (data: T) => void = Ø,
  onError: (error: Error) => void = Ø,
): [PromiseInvoker, State<T>] => {
  const [state, dispatch] = useReducer<Reducer<T>>(reducer, {
    loading: false,
  });

  const invoker = useCallback(
    (...args: any[]) => {
      dispatch({ type: 'start-loading' });

      creator(...args)
        .then(data => {
          dispatch({ type: 'success', data });

          onSuccess(data);
        })
        .catch(error => {
          dispatch({ type: 'error', error });

          onError(error);
        });
    },
    [creator, onSuccess, onError],
  );

  return [invoker, state];
};

export default usePromise;
