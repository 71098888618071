import React, { useState } from 'react'

import moment from 'moment';
import { MobileTimePicker } from '@mui/x-date-pickers';

import TextField from '@mui/material/TextField';

type Props = {
  label: string;
  value: number;
  onSelected: (value: number) => void;
}

const TimeSelector: React.FC<Props> = ({ label, value, onSelected }) => {
  const [date, setDate] = useState<moment.Moment>(moment().startOf('day').add({ minutes: value }));

  return (
    <>
      <MobileTimePicker
        label={label}
        minutesStep={5}
        renderInput={(params) => <TextField {...params} style={{ width: '100%' }} />}
        value={date}
        onChange={newDate => newDate ? setDate(newDate) : moment()}
        onAccept={(newValue) => {
          if (newValue) {
            const startOfDay = newValue.clone().startOf('day');

            onSelected(newValue.diff(startOfDay, 'minutes') || 0);
          }
        }}
      />
    </>
  );
}

export default TimeSelector