export const toWorkTime = (duration: number): string => {
  const hours = Math.floor(duration / 60);
  const minutes = Math.floor(duration % 60);

  const mappers = [
    [hours, (value: number) => `${value} godz.`],
    [minutes, (value: number) => `${value} min.`],
  ] as const;

  return mappers
    .filter(([time]) => time)
    .map(([time, parser]) => parser(time))
    .join(" i ");
};

export const toDayTime = (time: number): string => {
  const hours = Math.floor(time / 60);
  const minutes = Math.floor(time % 60);

  return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
};
