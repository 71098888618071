// import dateFnsGenerateConfig from "rc-picker/lib/generate/moment";
// import generatePicker from "antd/es/date-picker/generatePicker";
// import "antd/es/date-picker/style/index";
import { DatePicker } from "antd";

import moment from "moment";
import "moment/locale/pl";

moment.locale("es");

export default DatePicker;
