import { Routes, Route } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import AllReportsView from "./views/AllReportsView";
import MineReportsView from "./views/MineReportsView";

import NewReport from "./views/NewReport";
import ReportHistory from "./views/ReportHistory";
import UserReportsView from "./views/UserReportsView";

const UserRoutes: React.FC = () => {
  const {
    state: { user },
  } = useAuth();

  return (
    <Routes>
      <Route path="/" element={<MineReportsView />} />
      <Route path="/report/new" element={<NewReport />} />
      <Route path="/reports/mine" element={<MineReportsView />} />

      {(user?.role === "admin" || user?.role === "superadmin") && (
        <>
          <Route path="/reports" element={<AllReportsView />} />
          <Route path="/reports/:id/:name" element={<UserReportsView />} />
        </>
      )}

      {user?.role === "superadmin" && (
        <>
          <Route path="/reports/:id/history" element={<ReportHistory />} />
        </>
      )}
    </Routes>
  );
};

export default UserRoutes;
