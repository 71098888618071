import { Form, Input } from "antd";

const SystemForm = () => (
  <Form.Item
    label="Login"
    name="login"
    rules={[{ required: true, message: "Pole nie może być puste" }]}
  >
    <Input placeholder="Wprowadź login"  />
  </Form.Item>
);

export default SystemForm;
