import { get, patch, post, toParams } from "../../../lib/remote";
import { User } from "../../../types/auth";

import { NewUserPayload, UpdateUserPayload, UsersFilter } from "../d";

export const getUsers = (params?: UsersFilter) => get<User[]>(`/users${toParams(params)}`);

export const getUser = (id: number) => get<User>(`/users/${id}`);

export const createUser = (payload: NewUserPayload) => post<never, NewUserPayload>('/users', payload);

export const updateUser = (id: number, payload: UpdateUserPayload) => patch<never, NewUserPayload>(`/users/${id}`, payload);

export const updateStatus = (id: number, status: boolean) =>
  post(`/users/${id}/status`, { status });

export const changeMyPassword = (password: string) => patch('/users/password', { password });

export const changeUserPassword = (id: number, password: string) => patch(`/users/${id}/password`, { password });
