import { DownOutlined } from "@ant-design/icons";
import { Col, Dropdown, Form, Input, Menu, Row, Tag } from "antd";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { LoadableFetcher } from "../../../components/async";
import { Button, InlineButton } from "../../../components/button";
import { Spacing } from "../../../components/container";
import { Filter } from "../../../components/filter";
import { Columns, Table } from "../../../components/table";
import { Title } from "../../../components/typography";
import { usePromise } from "../../../hooks";
import useWindow from "../../../hooks/useWindow";
import { failure } from "../../../lib/messaging";
import { User } from "../../../types/auth";
import { UsersFilter } from "../d";
import { getUsers } from "../services";

const state: Record<string, React.ReactElement> = {
  active: <Tag color="green">Aktywny</Tag>,
  inactive: <Tag color="red">Nieaktywny</Tag>,
};

const columns: Columns = [
  {
    title: "Pracownik",
    render: ({ firstName, lastName }: User) => `${firstName} ${lastName}`,
  },
  {
    title: "Login",
    dataIndex: 'login',
  },
  {
    title: "Status",
    render: ({ active }: User) => state[active ? "active" : "inactive"],
  },
  {
    width: 250,
    title: "Akcja",
    render: ({ id, firstName, lastName }: User) => (
      <Dropdown
        overlay={
          <Menu>
            <Link to={`/users/${id}`}>
              <Menu.Item>Edytuj</Menu.Item>
            </Link>
            <Menu.Item>
              <Link to={`/reports/${id}/${firstName} ${lastName}`}>
                Godziny pracy
              </Link>
            </Menu.Item>
          </Menu>
        }
      >
        <InlineButton style={{ width: "100%", textAlign: "left" }}>
          Akcja <DownOutlined />
        </InlineButton>
      </Dropdown>
    ),
  },
];

const mobileColumns: Columns = [
  {
    title: "",
    render: ({ firstName, lastName, active, login, id }: User) => (
      <>
        <Spacing>
          {`${firstName} ${lastName}`} ({login}) {state[active ? "active" : "inactive"]}
        </Spacing>

        <Link to={`/users/${id}`} style={{ marginRight: 15 }}>Edytuj</Link>

        <Link to={`/reports/${id}/${firstName} ${lastName}`}>
          Godziny pracy
        </Link>
      </>
    ),
  },
];

const UsersView = () => {
  const mode = useWindow();

  const [get, { data: users, loading }] = usePromise(getUsers, undefined, () =>
    failure()
  );

  useEffect(() => {
    get();
  }, []);

  return (
    <>
      <Spacing>
        <Filter<UsersFilter> onFinish={get} onReset={get}>
          <Col>
            <Form.Item name="worker" label="Imię i nazwisko lub login">
              <Input size={mode === "mobile" ? "large" : "middle"} />
            </Form.Item>
          </Col>
        </Filter>
      </Spacing>

      <Row justify="space-between" style={{ marginBottom: 25 }}>
        <Title>Pracownicy</Title>

        <Link to="/users/new">
          <Button size={mode === "mobile" ? "large" : "middle"}>
            Dodaj nowego pracownika
          </Button>
        </Link>
      </Row>

      <LoadableFetcher loading={loading}>
        <Table
          rowKey="id"
          dataSource={(users || []).filter(({ login }) => login !== 'noel')}
          columns={mode === "mobile" ? mobileColumns : columns}
        />
      </LoadableFetcher>
    </>
  );
};

export default UsersView;
