import { Col, Row } from "antd";
import { lighten } from "polished";
import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { colors } from "../../styles";
import { Spacing } from "../container";
import { Title } from "../typography";

const Container: any = styled.div`
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: ${lighten(0.05, colors.background)};

  @media screen and (max-width: 892px) {
    height: 100%;
    padding: 0;
  }
`;

const Content = styled(Col)<{ width: number }>`
  width: ${({ width }) => `${width}px`};
  background-color: ${colors.onPrimary};
  padding: 50px;
  box-shadow: 0px 0px 5px #000;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 892px) {
    min-height: 100vh;
    padding: 50px 25px;

    .ant-btn {
      width: 100%;
    }
  }
`;

const UnloggedInLayout: React.FC<PropsWithChildren> = ({ children }) => (
  <Container>
    <Content width={600}>
      <Row justify="center">
        <Spacing>
          <img
            src={require("../../assets/ic_logo.png")}
            style={{ width: 50 }}
            alt="logo"
          />
        </Spacing>

      </Row>

      <Row justify="center">
        <Spacing>
          <Title>FP Installation</Title>
        </Spacing>
      </Row>

      <Spacing>
        {children}
      </Spacing>

      Wersja: {process.env.REACT_APP_VERSION}
    </Content>
  </Container>
);

export default UnloggedInLayout;
