import {
  format,
  startOfDay,
  add,
  endOfMonth,
  startOfMonth,
  formatISO as formatISOFp,
  getISODay,
} from "date-fns";

export const formatDate = (date: Date) => format(date, "dd.MM.yyyy");

export const formatDateTime = (date: Date) => format(date, "dd.MM.yyyy HH:mm");

export const formatDay = (date: Date) => {
  const days = ['Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota', 'Niedziela'];

  return days[getISODay(date) - 1];
}

export const formatCurrentDate = (pattern: string) => format(new Date(), pattern);

export const addToNow = (duration: Duration) => add(new Date(), duration);

export const now = () => new Date();

export const formatISO = (date: number | Date) =>
  formatISOFp(date, { representation: "date" });

export { startOfDay, endOfMonth, startOfMonth };
