export const toSignInError = (error: Error): string => {
  const { message } = error || {};
  
  if (message === 'INCORRECT_LOGIN_OR_PASSWORD') {
    return 'Nieprawidłowy login lub hasło';
  }
  
  if (message === 'ACCOUNT_DEACTIVATED') {
    return 'Twoje konto zostało tymczasowo wyłączone';
  }

  return 'Wystąpił nieznany błąd. Proszę spróbować ponownie';
}
