// https://chir.ag/projects/name-that-color
const alto = '#DDDDDD';
const denim = '#003FB4';
const flamingo = '#F44242';
const white = '#FFFFFF';
const funGreen = '#007337';
const black = '#0d0d0d';
const gold = '#f2b544';
const tamarillo = '#A6122D';

export default {
  border: alto,
  onPrimary: white,
  primary: tamarillo,
  excel: funGreen,
  error: flamingo,
  background: black,
  selected: gold,
  menu: denim,
};
