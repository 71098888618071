import { DeleteOutlined } from "@ant-design/icons";
import { Card, Modal, Row } from "antd";
import React from "react";
import { Button, InlineButton } from "../../../components/button";
import { Spacing } from "../../../components/container";
import { Text } from "../../../components/typography";
import { usePromise } from "../../../hooks";
import { failure } from "../../../lib/messaging";
import { colors } from "../../../styles";
import { Location, Report } from "../d";
import { toDayTime, toWorkTime } from "../mappers";
import { removeReport as removeReportAsync } from "../services";
import EditableReport from "./EditableReport";

type Props = {
  title: string;
  report: Report;
  options: Location[];
  onRemoved: () => void;
  onEdited: () => void;
};

const ReportForm: React.FC<Props> = ({
  title,
  report,
  options,
  onRemoved,
  onEdited,
}) => {
  const [removeReport] = usePromise(removeReportAsync, onRemoved, () => failure(),);

  const { id } = report;

  const remove = () =>
    Modal.confirm({
      title: "Czy na pewno chcesz usunąć ten raport?",
      onOk: () => removeReport(id),
      okText: "Usuń",
      cancelText: "Anuluj",
      okButtonProps: {
        style: {
          backgroundColor: colors.primary,
          borderColor: colors.primary,
        },
      },
    });

  const { duration, location, startAt, endAt } = report;

  return (
    <Spacing>
      <Card
        title={title}
        extra={
          <InlineButton>
            <DeleteOutlined onClick={remove} />
          </InlineButton>
        }
      >
        <Spacing>
          <Text>Godzina rozpoczęcia pracy: {toDayTime(startAt)}</Text>
        </Spacing>

        <Spacing>
          <Text>Godzina zakończenia pracy: {toDayTime(endAt)}</Text>
        </Spacing>

        <Spacing>
          <Text>Zarejestrowany czas pracy: {toWorkTime(duration)}</Text>
        </Spacing>

        <Spacing>
          <Text>Lokalizacja: {location.name}</Text>
        </Spacing>

        <Row>
          <EditableReport report={report} onEdited={onEdited} options={options}>
            <Button>Edytuj</Button>
          </EditableReport>
        </Row>
      </Card>
    </Spacing>
  );
};

export default ReportForm;
