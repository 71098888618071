import { useState, useLayoutEffect } from "react";

type Size = "mobile" | "desktop";

const useWindow = (): Size => {
  const [width, setWidth] = useState(window.innerWidth);

  useLayoutEffect(() => {
    const updateSize = () => setWidth(window.innerWidth);

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return width < 892 ? "mobile" : "desktop";
};

export default useWindow;
