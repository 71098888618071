import { Form, Select } from "antd";
import { useEffect } from "react";
import { usePromise } from "../../../hooks";
import useWindow from "../../../hooks/useWindow";
import { getAll } from "../services";

const LocationFilter: React.FC = () => {
  const mode = useWindow();

  const [get, { data, loading }] = usePromise(getAll);

  useEffect(() => {
    get();
  }, []);

  return (
    <Form.Item name="location" label="Lokalizacja">
      {!loading && (
        <Select size={mode === "mobile" ? "large" : "middle"} defaultValue={0}>
          <Select.Option value={0}>Wszystkie lokalizacje</Select.Option>

          {(data || []).map(({ id, name }) => (
            <Select.Option value={id}>{name}</Select.Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
};

export default LocationFilter;
