import { Col, Form, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, InlineButton } from "../../../components/button";
import { Spacing } from "../../../components/container";
import { Title } from "../../../components/typography";
import { usePromise } from "../../../hooks";
import { failure, success } from "../../../lib/messaging";
import { LocationForm } from "../components";
import { createLocation, getCurrencies } from "../services";

const NewLocationView: React.FC = () => {
  const [get, { data: currencies }] = usePromise(getCurrencies, undefined, () =>
    failure()
  );

  const [form] = useForm();

  const [create, { loading: creating }] = usePromise(
    createLocation,
    () => {
      form.resetFields();
      success("Lokalizacja została utworzona");
    },
    () => failure()
  );

  const navigate = useNavigate();

  useEffect(() => {
    get();
  }, []);

  return (
    <>
      <Form
        form={form}
        onFinish={create}
        layout="vertical"
      >
        <Spacing>
          <Row align="middle" gutter={[25, 25]}>
            <Col>
              <InlineButton onClick={() => navigate(-1)}>Wstecz</InlineButton>
            </Col>
            <Col>
              <Title>Dodawanie nowej lokalizacji</Title>
            </Col>
          </Row>
        </Spacing>

        <LocationForm currencies={currencies || []} />

        <Button loading={creating} htmlType="submit">
          Dodaj
        </Button>
      </Form>
    </>
  );
};

export default NewLocationView;
