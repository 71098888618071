import { AutoComplete, Form, Input, InputNumber } from "antd";
import React from "react";

type Props = {
  currencies: string[];
};

const LocationForm: React.FC<Props> = ({ currencies }) => (
  <>
    <Form.Item
      label="Nazwa"
      name="name"
      rules={[{ required: true, message: "Pole nie może być puste" }]}
    >
      <Input placeholder="Wprowadź nazwę lokalizacji" />
    </Form.Item>

    <Form.Item
      label="Koszt roboczogodziny"
      name="hourPrice"
      rules={[{ required: true, message: "Pole nie może być puste" }]}
    >
      <InputNumber
        min={0}
        placeholder="Wprowadź koszt roboczogodziny"
        style={{ width: "100%" }}
        decimalSeparator=","
        precision={2}
      />
    </Form.Item>

    <Form.Item
      label="Waluta rozliczenia"
      name="currency"
      rules={[{ required: true, message: "Pole nie może być puste" }]}
    >
      <AutoComplete
        options={currencies.map((currency) => ({ value: currency }))}
      />
    </Form.Item>
  </>
);

export default LocationForm;
