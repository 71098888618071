import { Reducer } from 'react';

import { Action, State } from './d';

const reducer: Reducer<State, Action> = (state, action): State => {
  switch (action.type) {
    case 'SIGN_IN':
      return {
        ...state,
        user: action.user,
      };

    case 'SIGN_OUT':
      return {
        ...state,
        user: undefined,
      };
  };
};

export default reducer;
