import { HashRouter as Router } from "react-router-dom";
import { LoggedInLayout, UnloggedInLayout } from "./components/layout";
import { useAuth } from "./contexts/auth";
import UserRoutes from "./features/users";
import ReportRoutes from "./features/report";
import AuthRoutes from "./features/auth";
import LocationsRoutes from "./features/locations";
import { usePromise } from "./hooks";
import { SplashScreen } from "./components/async";
import { getSignedInUser } from "./services/auth";
import { useEffect } from "react";
import WorkhoursRoutes from "./features/workhours";

const SignedInRoutes = () => (
  <LoggedInLayout>
    <UserRoutes />
    <ReportRoutes />
    <LocationsRoutes />
    <WorkhoursRoutes />
  </LoggedInLayout>
);

const UnsignedRoutes = () => (
  <UnloggedInLayout>
    <AuthRoutes />
  </UnloggedInLayout>
);

const App = () => {
  const { state, dispatch } = useAuth();

  const [get, { loading, data: user }] = usePromise(getSignedInUser);

  useEffect(() => {
    user && dispatch({ type: "SIGN_IN", user });
  }, [user]);

  useEffect(() => {
    get();
  }, []);

  return (
    <SplashScreen loading={loading}>
      <Router>{state.user ? <SignedInRoutes /> : <UnsignedRoutes />}</Router>
    </SplashScreen>
  );
};

export default App;

