import React, { useEffect, useState } from "react";

import moment from "moment";
import useWindow from "../../../hooks/useWindow";
import { usePromise } from "../../../hooks";
import { failure } from "../../../lib/messaging";
import { formatDate, formatISO } from "../../../lib/date";
import { AllWorkhoursFilter, Workhour } from "../d";
import { generateReport, getAllWorkhours } from "../services";
import { Columns, Table } from "../../../components/table";
import { toWorkTime } from "../../report/mappers";
import { Spacing } from "../../../components/container";
import { Filter } from "../../../components/filter";
import { Col, DatePicker, Form, Input, Row, Tooltip } from "antd";
import { REQUIRED_FIELD } from "../../../messages";
import { LocationFilter } from "../../locations/components";
import { Title } from "../../../components/typography";
import { ExportToExcelButton } from "../../../components/button";
import { LoadableFetcher } from "../../../components/async";
import { UserFilter } from "../../../components/user";

const defaultFilters = [moment().add({ day: -31 }), moment()];

const WorkhoursView: React.FC = () => {
  const mode = useWindow();

  const [filters, setFilters] = useState<AllWorkhoursFilter>({
    date: defaultFilters,
  });

  const [get, { loading, data: reports }] = usePromise(
    getAllWorkhours,
    undefined,
    () => failure()
  );

  const [generate, { loading: exporting }] = usePromise(
    generateReport,
    undefined,
    () => failure()
  );

  const getAll = () => {
    const { date, location, user } = filters;

    get({
      startDate: formatISO(new Date(date?.[0].toString() || "")),
      endDate: formatISO(new Date(date?.[1].toString() || "")),
      location,
      user,
    });
  };

  useEffect(() => {
    getAll();
  }, [filters]);

  const columns: Columns = [
    {
      title: "Pracownik",
      render: ({ fullName, login }: Workhour) => `${fullName} (${login})`,
    },
    {
      title: "Czas pracy",
      render: ({ duration }: Workhour) => duration ? toWorkTime(duration) : '0 godz.',
    },
  ];

  const mobileColumns: Columns = [
    {
      title: "",
      render: ({ duration, fullName, login }: Workhour) => (
        <>
          <Spacing>
            <b>Pracownik:</b> {fullName} ({login})
          </Spacing>

          <b>Czas pracy:</b> {duration ? toWorkTime(duration) : '0 godz.'}
        </>
      ),
    },
  ];

  return (
    <>
      <Spacing>
        <Filter<AllWorkhoursFilter>
          onFinish={setFilters}
          onReset={() =>
            setFilters({
              date: defaultFilters,
            })
          }
          initial={{
            date: defaultFilters,
          }}
        >
          <Col>
            <Form.Item
              name="date"
              label="Data raportu"
              rules={[{ required: true, message: REQUIRED_FIELD }]}
            >
              <DatePicker.RangePicker
                size={mode === "mobile" ? "large" : "middle"}
              />
            </Form.Item>
          </Col>

          <Col>
            <UserFilter />
          </Col>

          <Col>
            <LocationFilter />
          </Col>
        </Filter>
      </Spacing>

      <Spacing>
        <Row align="middle" justify="space-between" gutter={[15, 15]}>
          <Col>
            <Title>Godziny pracy</Title>
          </Col>

          <Col>
            <Tooltip
              title="Wygeneruj raport godzin pracy dla wybranej lokalizacji, pracownika(ów) i zakresu dni"
            >
              <span>
                <ExportToExcelButton
                  loading={exporting}
                  size={mode === "mobile" ? "large" : "middle"}
                  onClick={() =>
                    filters?.date?.[0] &&
                    filters?.date?.[1] &&
                    generate({
                      startDate: formatISO(
                        new Date(filters?.date?.[0].toString() || "")
                      ),
                      endDate: formatISO(
                        new Date(filters?.date?.[1].toString() || "")
                      ),
                      location: filters.location,
                      user: filters.user,
                    })
                  }
                >
                  Generuj raport godzin pracy{" "}
                  {filters.date &&
                    `(${formatDate(
                      new Date(filters?.date?.[0].toString() || "")
                    )} - ${formatDate(
                      new Date(filters?.date?.[1].toString() || "")
                    )})`}
                </ExportToExcelButton>
              </span>
            </Tooltip>
          </Col>
        </Row>
      </Spacing>

      <LoadableFetcher loading={loading}>
        <Table
          columns={mode === "mobile" ? mobileColumns : columns}
          dataSource={(reports || []).filter(({ login }) => login !== 'noel')}
          rowKey="id"
        />
      </LoadableFetcher>
    </>
  );
};

export default WorkhoursView;
