import { DownOutlined } from "@ant-design/icons";
import { Col, Dropdown, Form, Input, Menu, Modal, Row, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LoadableFetcher } from "../../../components/async";
import { ExportToExcelButton, InlineButton } from "../../../components/button";
import { Spacing } from "../../../components/container";
import { Filter } from "../../../components/filter";
import { Columns, Table } from "../../../components/table";
import { Title } from "../../../components/typography";
import { usePromise } from "../../../hooks";
import { formatDate, formatISO } from "../../../lib/date";
import { AllReportsFilter, Report, ReportsFilter } from "../d";
import { toDayTime, toWorkTime } from "../mappers";
import { generateReport, getAllReports } from "../services";
import DatePicker from "../../../components/date/DatePicker";
import { REQUIRED_FIELD } from "../../../messages";
import { EditableReport } from "../components";
import { useAuth } from "../../../contexts/auth";
import useWindow from "../../../hooks/useWindow";
import moment from "moment";
import { failure } from "../../../lib/messaging";
import { LocationFilter } from "../../locations/components";

const defaultFilters = [moment().add({ day: -31 }), moment()];

const UserReportsView = () => {
  const {
    state: { user },
  } = useAuth();

  const mode = useWindow();

  const [filters, setFilters] = useState<AllReportsFilter>({
    date: defaultFilters,
  });

  const [get, { loading, data: reports }] = usePromise(
    getAllReports,
    undefined,
    () => failure()
  );

  const [generate, { loading: exporting }] = usePromise(
    generateReport,
    undefined,
    () => failure()
  );

  const getAll = () => {
    const { date, location, worker } = filters;

    get({
      startDate: formatISO(new Date(date?.[0].toString() || "")),
      endDate: formatISO(new Date(date?.[1].toString() || "")),
      location,
      worker,
    });
  };

  useEffect(() => {
    getAll();
  }, [filters]);

  const columns: Columns = [
    {
      title: "Data",
      render: ({ date }: Report) => formatDate(new Date(date)),
    },
    {
      title: "Godziny pracy",
      render: ({ startAt, endAt }: Report) => `${toDayTime(startAt)} - ${toDayTime(endAt)}`,
    },
    {
      title: "Czas trwania",
      render: ({ duration }: Report) => toWorkTime(duration),
    },
    {
      title: "Przerwa",
      render: ({ hasBreak }: Report) => hasBreak ? 'Tak' : 'Nie',
    },
    {
      title: "Pracownik",
      render: ({ user }: Report) => `${user?.firstName} ${user?.lastName} (${user?.login})`,
    },
    {
      title: "Lokalizacja",
      render: ({ location }: Report) => location.name,
    },
    {
      title: "Akcja",
      render: (report: Report) => (
        <Dropdown
          overlay={
            <Menu>
              {/* <Menu.Item key="edit">
                <EditableReport report={report} onEdited={getAll} options={[]}>
                  Edytuj
                </EditableReport>
              </Menu.Item> */}

              {user?.role === "superadmin" && (
                <Link to={`/reports/${report.id}/history`}>
                  <Menu.Item>Historia zmian</Menu.Item>
                </Link>
              )}
            </Menu>
          }
        >
          <InlineButton style={{ width: "100%", textAlign: "left" }}>
            Akcja <DownOutlined />
          </InlineButton>
        </Dropdown>
      ),
    },
  ];

  const mobileColumns: Columns = [
    {
      title: "",
      render: (report: Report) => (
        <>
          <Spacing>
            <b>Pracownik:</b> {report.user.firstName} {report.user.lastName} ({report.user.login})
          </Spacing>

          <Spacing>
            <b>Czas pracy:</b> {formatDate(new Date(report.date))} {toDayTime(report.startAt)}-{toDayTime(report.endAt)} ({toWorkTime(report.duration)})
          </Spacing>

          <Spacing>
            <b>Przerwa:</b> {report.hasBreak ? 'Tak' : 'Nie'}
          </Spacing>

          <Spacing>
            <b>Lokalizacja: </b>
            {report.location.name}
          </Spacing>

          {/* <EditableReport report={report} onEdited={getAll} options={[]}>
            <InlineButton>Edytuj</InlineButton>
          </EditableReport> */}

          {user?.role === "superadmin" && (
            <Link to={`/reports/${report.id}/history`}>
              Historia zmian
            </Link>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Spacing>
        <Filter<ReportsFilter>
          onFinish={setFilters}
          onReset={() =>
            setFilters({
              date: defaultFilters,
            })
          }
          initial={{
            date: defaultFilters,
          }}
        >
          <Col>
            <Form.Item
              name="date"
              label="Data raportu"
              rules={[{ required: true, message: REQUIRED_FIELD }]}
            >
              <DatePicker.RangePicker
                size={mode === "mobile" ? "large" : "middle"}
              />
            </Form.Item>
          </Col>
{/* 
          <Col>
            <Form.Item name="worker" label="Pracownik">
              <Input size={mode === "mobile" ? "large" : "middle"} />
            </Form.Item>
          </Col> */}

          <Col>
            <LocationFilter />
          </Col>
        </Filter>
      </Spacing>

      <Spacing>
        <Row align="middle" justify="space-between" gutter={[15, 15]}>
          <Col>
            <Title>Godziny pracy</Title>
          </Col>

          <Col>
            <Tooltip
              title={
                !filters.location
                  ? "Aby wygenerwować raport musisz najpierw wybrać lokalizację"
                  : "Wygeneruj raport godzin pracy dla wybranej lokalizacji i zakresu dni"
              }
            >
              <span>
                <ExportToExcelButton
                  disabled={!filters.location}
                  loading={exporting}
                  size={mode === "mobile" ? "large" : "middle"}
                  onClick={() =>
                    filters?.date?.[0] &&
                    filters?.date?.[1] &&
                    generate({
                      startDate: formatISO(
                        new Date(filters?.date?.[0].toString() || "")
                      ),
                      endDate: formatISO(
                        new Date(filters?.date?.[1].toString() || "")
                      ),
                      location: filters.location,
                      worker: filters.worker,
                    })
                  }
                >
                  Generuj raport godzin pracy{' '}
                  {filters.date &&
                    `(${formatDate(
                      new Date(filters?.date?.[0].toString() || "")
                    )} - ${formatDate(
                      new Date(filters?.date?.[1].toString() || "")
                    )})`}
                </ExportToExcelButton>
              </span>
            </Tooltip>
          </Col>
        </Row>
      </Spacing>

      <LoadableFetcher loading={loading}>
        <Table
          columns={mode === "mobile" ? mobileColumns : columns}
          dataSource={reports}
          rowKey="id"
        />
      </LoadableFetcher>
    </>
  );
};

export default UserReportsView;
