import React from "react";
import styled from "styled-components";
import { formatDate, formatDay } from "../../../lib/date";
import { colors } from "../../../styles";
import { Report } from "../d";
import { toDayTime, toWorkTime } from "../mappers";

type Props = {
  date: Date;
  reports: Report[];
  onSelect: (date: Date) => void;
};

const DayReports: React.FC<Props> = ({ date, reports, onSelect }) => {
  const events = reports.filter(
    (report) => formatDate(new Date(report.date)) === formatDate(date)
  );

  return (
    <Container onClick={() => onSelect(date)}>
      <div style={{ marginBottom: 10 }}>
        {formatDay(date)}
      </div>

      {events.map(({ id, startAt, endAt, location, duration }) => (
        <div key={id}>
          <span style={{ color: colors.primary }}>
            {toDayTime(startAt)} - {toDayTime(endAt)}
          </span>{' '}
          
          ({toWorkTime(duration)}) - {location.name}
        </div>
      ))}
    </Container>
  );
};

const Container: any = styled.div`
  height: 100%;
`;

export default DayReports;
