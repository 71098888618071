import React, { PropsWithChildren } from 'react'

type Props = PropsWithChildren & {
  spacing?: number;
};

const Spacing: React.FC<Props> = ({ spacing = 25, children }) => (
  <div style={{ marginBottom: spacing }}>
    {children}
  </div>
)

export default Spacing