import { Routes, Route } from "react-router-dom";
import AccountView from "./views/AccountView";
import EditUserForm from "./views/EditUsersView";

import NewUserForm from "./views/NewUserForm";
import UsersView from "./views/UsersView";

const UserRoutes: React.FC = () => (
  <Routes>
    <Route path="/account" element={<AccountView />} />
    <Route path="/users" element={<UsersView />} />
    <Route path="/users/new" element={<NewUserForm />} />
    <Route path="/users/:id" element={<EditUserForm />} />
  </Routes>
);

export default UserRoutes;
