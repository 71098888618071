import { Form, Input } from "antd";
import React from "react";

const UserForm: React.FC = () => (
  <>
    <Form.Item
      label="Imię"
      name="firstName"
      rules={[{ required: true, message: "Pole nie może być puste" }]}
    >
      <Input  placeholder="Wprowadź imię" />
    </Form.Item>

    <Form.Item
      label="Nazwisko"
      name="lastName"
      rules={[{ required: true, message: "Pole nie może być puste" }]}
    >
      <Input  placeholder="Wprowadź nazwisko" />
    </Form.Item>

    <Form.Item
      label="Adres email"
      name="email"
      rules={[{ type: 'email', message: "Proszę wprowadzić prawidłowy adres email" }]}
    >

      <Input  placeholder="Wprowadź adres email (opcjonalne)" />
    </Form.Item>
  </>
);

export default UserForm;
