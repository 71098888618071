import { Col, Form, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useNavigate } from "react-router-dom";
import { Button, InlineButton } from "../../../components/button";
import { Spacing } from "../../../components/container";
import { Title } from "../../../components/typography";
import { usePromise } from "../../../hooks";
import { failure, success } from "../../../lib/messaging";
import { PasswordForm, SystemForm, UserForm } from "../components";
import { createUser } from "../services";

const NewUserForm = () => {
  const [form] = useForm();

  const [create, { loading }] = usePromise(
    createUser,
    () => {
      form.resetFields();
      success("Konto zostało utworzone")
    },
    () => failure()
  );

  const navigate = useNavigate();

  return (
    <>
      <Form form={form} onFinish={create} layout="vertical">
        <Spacing>
          <Row align="middle" gutter={[25, 25]}>
            <Col>
              <InlineButton onClick={() => navigate(-1)}>Wstecz</InlineButton>
            </Col>
            <Col>
              <Title>Dodawanie nowego pracownika</Title>
            </Col>
          </Row>
        </Spacing>

        <UserForm />
        <SystemForm />
        <PasswordForm />

        <Button loading={loading} htmlType="submit">
          Dodaj
        </Button>
      </Form>
    </>
  );
};

export default NewUserForm;
