import { Col, Form, Row } from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LoadableFetcher } from "../../../components/async";
import { Button, InlineButton } from "../../../components/button";
import { Spacing } from "../../../components/container";
import { Title } from "../../../components/typography";
import { usePromise } from "../../../hooks";
import { failure, success } from "../../../lib/messaging";
import { PasswordForm, SystemForm, UserForm } from "../components";
import SetStatus from "../components/SetStatus";
import { changeUserPassword, getUser, updateUser } from "../services";

const EditUserForm = () => {
  const { id } = useParams();

  const [get, { loading, data }] = usePromise(getUser, undefined, () => failure());

  const [update, { loading: updating }] = usePromise(updateUser, () => success('Konto zostało zaktualizowane'), () => failure());

  const [changePassword, { loading: changing }] = usePromise(changeUserPassword, () => success('Hasło zostało zmienione'), () => failure());

  useEffect(() => {
    get(id);
  }, []);

  const navigate = useNavigate();

  return (
    <LoadableFetcher loading={loading}>
      <Spacing>
        <Form
          onFinish={(payload) => update(id, payload)}
          layout="vertical"
          initialValues={data}
        >
          <Spacing>
            <Row align="middle" justify="space-between" gutter={[25, 25]}>
              <Col>
                <Row align="middle" gutter={[25, 25]}>
                  <Col>
                    <InlineButton onClick={() => navigate(-1)}>
                      Wstecz
                    </InlineButton>
                  </Col>
                  <Col>
                    <Title>Edycja pracownika</Title>
                  </Col>
                </Row>
              </Col>
              <Col>{data && <SetStatus id={data.id} active={data.active} />}</Col>
            </Row>
          </Spacing>

          <UserForm />
          <SystemForm />

          <Button loading={updating} htmlType="submit">
            Zapisz dane
          </Button>
        </Form>
      </Spacing>

      {/* TODO: Move to another file */}
      <Form layout="vertical" onFinish={({ password }) => changePassword(id, password)}>
        <PasswordForm />

        <Button loading={changing} htmlType="submit">
          Zmień hasło
        </Button>
      </Form>
    </LoadableFetcher>
  );
};

export default EditUserForm;
