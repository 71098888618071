import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { LoadableFetcher } from "../../../components/async";
import { Spacing } from "../../../components/container";
import { Columns, Table } from "../../../components/table";
import { Title } from "../../../components/typography";
import { usePromise } from "../../../hooks";
import useWindow from "../../../hooks/useWindow";
import { formatDate, formatDateTime } from "../../../lib/date";
import { failure } from "../../../lib/messaging";
import { ReportHistory } from "../d";
import { toDayTime, toWorkTime } from "../mappers";
import { toState } from "../mappers/mappers";
import { getReportHistory } from "../services";

const columns: Columns = [
  {
    title: "Data modyfikacji",
    render: ({ modifiedAt }: ReportHistory) =>
      formatDateTime(new Date(modifiedAt)),
  },
  {
    title: "Data",
    render: ({ date }: ReportHistory) =>
      date ? formatDate(new Date(date)) : "-",
  },
  {
    title: "Godziny pracy",
    render: ({ startAt, endAt }: ReportHistory) =>
      startAt && endAt ? `${toDayTime(startAt)}-${toDayTime(endAt)}` : '-'
  },
  {
    title: "Czas trwania",
    render: ({ duration }: ReportHistory) => toWorkTime(duration || 0),
  },
  {
    title: "Przerwa",
    render: ({ hasBreak }: ReportHistory) => hasBreak ? 'Tak' : 'Nie',
  },
  {
    title: "Lokalizacja",
    dataIndex: "location",
  },
  {
    title: "Zmodyfikowany przez",
    render: ({ modifiedBy }: ReportHistory) =>
      `${modifiedBy.firstName} ${modifiedBy.lastName} (${modifiedBy.login})`,
  },
  {
    title: "Stan",
    render: ({ state }: ReportHistory) => toState(state),
  },
];

const mobileColumns: Columns = [
  {
    title: "Historia raportu",
    render: ({
      modifiedAt,
      date,
      duration,
      modifiedBy,
      state,
      location,
      hasBreak,
      startAt,
      endAt
    }: ReportHistory) => (
      <>
        <Spacing>
          <b>Data modyfikacji: </b>
          {formatDate(new Date(modifiedAt))}
        </Spacing>

        <Spacing>
          <b>Data: </b>
          {date ? formatDate(new Date(date)) : "-"}
        </Spacing>

        <Spacing>
          <>
            <b>Lokalizacja: </b>
            {location}
          </>
        </Spacing>

        <Spacing>
          <b>Godziny pracy: </b>
          {startAt && endAt ? `${toDayTime(startAt)}-${toDayTime(endAt)}` : '-'}
        </Spacing>

        <Spacing>
          <b>Czas trwania: </b>
          {toWorkTime(duration || 0)}
        </Spacing>

        <Spacing>
            <b>Przerwa: </b>{hasBreak ? 'Tak' : 'Nie'}
        </Spacing>

        <Spacing>
          <b>Zmodyfikowany przez: </b>
          {`${modifiedBy.firstName} ${modifiedBy.lastName} (${modifiedBy.login})`}
        </Spacing>

        {toState(state)}
      </>
    ),
  },
];

const ReportHistoryComponent = () => {
  const { id } = useParams();

  const mode = useWindow();

  const [get, { data, loading }] = usePromise(getReportHistory, undefined, () =>
    failure()
  );

  useEffect(() => {
    get(id);
  }, []);

  return (
    <>
      <Spacing>
        <Title>Historia zmian</Title>
      </Spacing>

      <LoadableFetcher loading={loading}>
        <Table
          dataSource={data}
          columns={mode === "mobile" ? mobileColumns : columns}
          rowKey="id"
        />
      </LoadableFetcher>
    </>
  );
};

export default ReportHistoryComponent;
