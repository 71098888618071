import { Card, Col, Form, Row } from "antd";
import { useEffect, useState } from "react";
import { LoadableFetcher } from "../../../components/async";
import { Spacing } from "../../../components/container";
import { Filter } from "../../../components/filter";
import { Text, Title } from "../../../components/typography";
import { usePromise } from "../../../hooks";
import { formatISO, formatDate } from "../../../lib/date";
import { getMyReports } from "../services";
import DatePicker from "../../../components/date/DatePicker";
import { REQUIRED_FIELD } from "../../../messages";
import useWindow from "../../../hooks/useWindow";
import moment from 'moment';
import { failure } from "../../../lib/messaging";
import { ReportsFilter } from "../d";
import { toWorkTime } from "../mappers";
import { Link } from "react-router-dom";

const defaultFilters = [moment().add({ day: -31 }), moment()];

const MineReportsView = () => {
  const mode = useWindow();

  const [filters, setFilters] = useState<ReportsFilter>({
    date: defaultFilters,
  });

  const [get, { loading, data }] = usePromise(getMyReports, undefined, () => failure(),);

  const getAll = () => {
    const { date, location } = filters;

    get({
      startDate: formatISO(new Date(date?.[0].toString() || "")),
      endDate: formatISO(new Date(date?.[1].toString() || "")),
      location,
    });
  };

  useEffect(() => {
    getAll();
  }, [filters]);


  return (
    <>
      <Spacing>
        <Filter<ReportsFilter>
          onFinish={setFilters}
          onReset={() =>
            setFilters({
              date: defaultFilters,
            })
          }
          initial={{
            date: defaultFilters,
          }}
        >
          <Col>
            <Form.Item
              name="date"
              label="Data raportu"
              rules={[{ required: true, message: REQUIRED_FIELD }]}
            >
              <DatePicker.RangePicker size={mode === 'mobile' ? 'large' : 'middle'} />
            </Form.Item>
          </Col>
        </Filter>
      </Spacing>

      <Spacing>
        <Row align="middle" justify="space-between" gutter={[15, 15]}>
          <Col>
            <Title>Moje godziny pracy</Title>
          </Col>
        </Row>
      </Spacing>

      <LoadableFetcher loading={loading}>
        <Card>
          <Title style={{ marginBottom: 10 }}><b>Wybrany okres:</b> {formatDate(new Date(filters?.date?.[0]?.toString()))} - {formatDate(new Date(filters?.date?.[1]?.toString()))}</Title>
          <Title><b>Suma godzin pracy:</b> {toWorkTime(data?.stats._sum.duration || 0) || 0}</Title>
        </Card>
      </LoadableFetcher>
    </>
  );
};

export default MineReportsView;
