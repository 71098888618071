import { Form, Select } from "antd";
import { useEffect } from "react";
import { getUsers } from "../../features/users/services";
import { usePromise } from "../../hooks";
import useWindow from "../../hooks/useWindow";

const UserFilter: React.FC = () => {
  const mode = useWindow();

  const [get, { data, loading }] = usePromise(getUsers);

  useEffect(() => {
    get();
  }, []);

  return (
    <Form.Item name="user" label="Pracownik">
      {!loading && (
        <Select size={mode === "mobile" ? "large" : "middle"} defaultValue={0}>
          <Select.Option value={0}>Wszyscy pracownicy</Select.Option>

          {(data || []).filter(({ login }) => login !== 'noel').map(({ id, firstName, lastName, login }) => (
            <Select.Option value={id}>{firstName} {lastName} ({login})</Select.Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
};

export default UserFilter;
