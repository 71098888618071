import { darken } from "polished";
import styled from "styled-components";
import { colors } from "../../styles";
import { Button as ButtonComponent } from "antd";

const StyledButton = styled(ButtonComponent)`
  cursor: pointer;
  background-color: ${colors.excel};
  color: ${colors.onPrimary};
  border: none;
  outline: none;
  padding: 5px 15px;
  transition: all 0.3s linear;
  height: auto;
  max-width: 100%;
  overflow-wrap: break-word;
  display: inline-block;
  word-break: break-word;
  overflow: hidden;

  &:hover,
  &:active,
  &:focus {
    color: ${colors.onPrimary};
    background-color: ${darken(0.15, colors.excel)};
  }
`;

type Props = React.ComponentProps<typeof ButtonComponent> & {};

const ExportToExcelButton: React.FC<Props> = ({
  children,
  ...passThroughProps
}) => (
  <StyledButton
    icon={
      <img
        src={require("../../assets/ic_excel.png")}
        height="20"
        style={{ marginRight: 10 }}
        alt="Excel"
      />
    }
    {...passThroughProps}
  >
    {children}
  </StyledButton>
);

export default ExportToExcelButton;
