import { Layout, Row } from "antd";
import { PropsWithChildren } from "react";
import styled from "styled-components";
import { colors } from "../../styles";
import { SignedInMenu } from "../menu";
import { Link } from "react-router-dom";
import { Button } from "../button";
import Avatar from "./Avatar";
import useWindow from "../../hooks/useWindow";
import {
  FileProtectOutlined,
  FlagOutlined,
  SettingOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { BottomTab, BottomTabButton } from "../tab";
import { useAuth } from "../../contexts/auth";

const { Header, Sider, Content } = Layout;

const StyledSider = styled(Sider)`
  min-height: 100vh;
  color: #fff;
  border-right: 1px solid ${colors.border};
`;

const StyledHeader = styled(Header)`
  background-color: ${colors.onPrimary};
  box-shadow: 0px 0px 5px #888;
  z-index: 9;
  height: auto;
  line-height: unset;
  padding: 25px 50px;

  @media screen and (max-width: 892px) {
    padding: 20px;
  }
`;

const StyledContent = styled(Content)`
  background-color: ${colors.onPrimary};
  padding: 50px;

  @media screen and (max-width: 892px) {
    padding: 20px 20px 80px 20px;
  }
`;

const LoggedInLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const mode = useWindow();

  const { state } = useAuth();

  return (
    <Layout>
      {mode === "desktop" && (
        <StyledSider width={250}>
          <SignedInMenu theme="dark" />
        </StyledSider>
      )}

      <Layout>
        <StyledHeader>
          <Row justify="space-between" align="middle">
            <div>
              {mode === "desktop" && (
                <img
                  src={require("../../assets/ic_logo.png")}
                  style={{ width: 35, marginRight: 50 }}
                  alt="logo"
                />
              )}

              <Link to="/report/new">
                <Button size={mode === "mobile" ? "large" : "middle"}>
                  Dodaj godziny pracy
                </Button>
              </Link>
            </div>

            {mode === "desktop" && <Avatar />}

            {mode === "mobile" && (
              <img
                src={require("../../assets/ic_logo.png")}
                style={{ width: 35 }}
                alt="logo"
              />
            )}
          </Row>
        </StyledHeader>

        <StyledContent>{children}</StyledContent>
      </Layout>

      {mode === "mobile" && (
        <BottomTab>
          {state.user?.role === "worker" && (
            <>
              <BottomTabButton
                icon={<FileProtectOutlined />}
                location="/reports/mine"
                title="Raporty"
                altLocation="/"
              />
              <BottomTabButton
                icon={<SettingOutlined />}
                location="/report/new"
                title="Praca"
              />
              <BottomTabButton
                icon={<UserOutlined />}
                location="/account"
                title="Konto"
              />
            </>
          )}

          {(state.user?.role === "admin" ||
            state.user?.role === "superadmin") && (
            <>
              <BottomTabButton
                icon={<SettingOutlined />}
                location="/reports/mine"
                title="Praca"
                altLocation="/"
              />
              <BottomTabButton
                icon={<TeamOutlined />}
                location="/users"
                title="Pracownicy"
              />
              <BottomTabButton
                icon={<FlagOutlined />}
                location="/locations"
                title="Lokalizacje"
              />
              <BottomTabButton
                icon={<FileProtectOutlined />}
                location="/reports"
                title="Raporty"
              />
              <BottomTabButton
                icon={<UserOutlined />}
                location="/account"
                title="Konto"
              />
            </>
          )}
        </BottomTab>
      )}
    </Layout>
  );
};

export default LoggedInLayout;
